import {
  setBookingBedroomId,
  setBookingType,
  setBookUrl,
  setBuilderSettingsData,
  setCapacityMax,
  setHasWebhotelier,
  setHidePrices,
  setInquiriesOnly,
  setIsBookingsActive, setIsChain,
  setIsExperiencesPage, setIsHotel,
  setIsListingPage,
  setIsOpenCalendar, setLoadingProperties,
  setMaxBedrooms,
  setMinBedrooms,
  setPageId,
  setPropertiesData,
  setPropertiesFiltersData,
  setPropertyClassification,
  setPropertyId,
  setPropertySlug,
  setPropertyTypeTitle,
  setRoomCode,
  updateCaptchaSiteKey,
  updateHideUnavailableProperties,
  updatepropertyAvailability,
  updatepropertyAvailabilityForCheckout,
  updateWhDirectCheckout
} from '../redux/actions';
import Checkers from "./class/Checkers";
import {fetchBuilderSettingByKey} from "./MethodHelper";
import MD5 from "crypto-js/md5";
import {ServerResponse} from "http";
import LgConsts from "./LgConsts";

export function log ( message ) {
  // eslint-disable-next-line no-console
  console.log( message );
}

export function EncodeQueryData ( params ) {
  const result = [];
  for ( const param in params ) {
    const value = params[param];
    if ( value != null ) {
      result.push( encodeURIComponent( param ) + "=" + encodeURIComponent( value ) );
    }
  }
  return result.join( "&" );
}

export function urlParser ( env_scheme, env_api_url, endpoint, key = null, params = null ) {
  let url;

  url = env_scheme + env_api_url + endpoint;

  if ( params != null ) {
    url += '?' + EncodeQueryData( params );
  } else if ( key != null ) {
    url += key;
  }

  return url;
}

export function urlUseEffectParser ( endpoint, params ) {
  let url;

  url = endpoint;
  if ( params != null ) {
    url += '?' + EncodeQueryData( params );
  }

  return url;
}

export function generateHash ( str, seed = 0 ) {
  return MD5(str).toString()
}

export async function fetchData ( redis, endpoint, type = null, key = null, params = null, bypass_key = 0, page_id = null, showConsoleLogs = false ) {
  let data = null;

  let stringForHash = ''
  if (params != null) {
    const queryParams = new URLSearchParams(params);

    let stringForHashPart = queryParams.toString()
    stringForHashPart = stringForHashPart.replace(/=/g, "__")
    stringForHashPart = stringForHashPart.replace(/&/g, "__")
    stringForHashPart = stringForHashPart.replace("?", "-__")

    if(showConsoleLogs){
      console.log("stringForHashPart", stringForHashPart)
    }

    stringForHashPart = stringForHashPart.replace(/\W/g, '')

    if(showConsoleLogs){
      console.log("stringForHashPart", stringForHashPart)
    }

    stringForHash = endpoint.replace(/\//g, "_") + "-" + stringForHashPart //JSON.stringify(params).replace(/\W/g, '_')
  } else {
    stringForHash = endpoint.replace(/\//g, "_")
    stringForHash = stringForHash.replace(/=/g, "__")
    stringForHash = stringForHash.replace(/&/g, "__")
    stringForHash = stringForHash.replace("?", "-__")
  }

  if (stringForHash.charAt(stringForHash.length-1) != "_") {
    stringForHash = stringForHash + "_"
  }

  let cache_hash_id = generateHash(stringForHash)
  let cache_id = ''
  if(key!=null) {
    cache_id = type + "-" + key + "-" + cache_hash_id;
  }else{
    cache_id = cache_hash_id;
  }

  if(page_id!=null){
    cache_id = "page-" + page_id + '-' + cache_id
    // if (params == null) {
    //   params = {};
    // }
    // params['page_id'] = page_id
  }

  if(showConsoleLogs) {
    // eslint-disable-next-line no-console
    console.log("forDebug", "cache_id", cache_id, "endpoint", stringForHash) //, "stringForHash", stringForHash
  }

  let cache = await redis.get(cache_id)

  if (bypass_key == 1) {
    key = null;
    type = null
  }

  if (cache && bypass_key == 0) {
    // eslint-disable-next-line no-console
    console.log( "load from cache" )

    // eslint-disable-next-line no-console
    // console.log("forDebug", "cache_id", cache_id, "endpoint", stringForHash) //, "stringForHash", stringForHash

    data = JSON.parse(cache);
  } else {
    // eslint-disable-next-line no-console
    console.log("load from api", endpoint)
    // console.log("load from api", [cache_id, endpoint, stringForHash])

    if(showConsoleLogs) {
      // eslint-disable-next-line no-console
      console.log("forDebug", "cache_id", cache_id, "endpoint", endpoint, "stringForHash", stringForHash) //, "stringForHash", stringForHash
      console.log("forDebug", urlParser(process.env.SCHEME, process.env.API_URL, endpoint, key, params))
    }

    data = await fetch(urlParser(process.env.SCHEME, process.env.API_URL, endpoint, key, params), {
      headers: {
        "x-api-key": process.env.API_KEY
      }
    })

    data = await data.json()
    redis.set(cache_id, JSON.stringify(data), "EX", 31553280)
  }
  // console.log(process.env.SCHEME, process.env.API_URL, endpoint, key, params);
  return data;
}

export async function fetchDataNoCache ( endpoint, params = null ) {
  let key = "rewrite_fetch"

  let data = await fetch( urlParser( process.env.SCHEME, process.env.API_URL, endpoint, key, params ), {
    headers: {
      "x-api-key": process.env.API_KEY
    }
  })

  data = await data.json()
  return data;
}

export async function fetchDataPost ( redis, endpoint, type = null, key = null, params = null, bypass_key = 0, method = 'get', json_data = '' ) {
  let data = null;

  let cache_hash_id = generateHash( endpoint.replace( /\//g, "_" ) + "-" + JSON.stringify( params ).replace( /\W/g, '_' ) )
  const cache_id = type+"-"+key+"-"+cache_hash_id;

  const cache = false;// await redis.get( cache_id )

  if ( bypass_key==1 ) {
    key = null;
    type = null
  }

  if ( cache ) {
    // eslint-disable-next-line no-console
    // console.log( "load from cache" )

    data = JSON.parse( cache );
  } else {
    // eslint-disable-next-line no-console
    // console.log( "load from api" )

    data = await fetch(
        urlParser( process.env.SCHEME, process.env.API_URL, endpoint, key, params ),
        {
          method: method,
          body: json_data,
          headers: {
            "x-api-key": process.env.API_KEY
          }
        })

    data = await data.json()
    redis.set( cache_id, JSON.stringify( data ), "EX", 31553280 )
  }
  // console.log(process.env.SCHEME, process.env.API_URL, endpoint, key, params);
  return data;
}

export async function fetchSectionsData ( redis, endpoint, type, key, sectionType ) {
  let data = null
  const cache_id = type+"-"+key+endpoint.replace( /\//g, "_" ) + sectionType;

  const cache = await redis.get( cache_id )

  if ( cache ) {
    // console.log("load from cache")
    data = JSON.parse( cache );
  } else {
    // console.log("load from api")
    data = await fetch( process.env.SCHEME + process.env.API_URL + endpoint + key + sectionType, {
      headers: {
        "x-api-key": process.env.API_KEY
      }
    })
    data = await data.json()
    redis.set( cache_id, JSON.stringify( data ), "EX", 31553280 )
  }

  return data;
}

export async function fetchAppData ( req, redis, res: ServerResponse ) {
  const domain = req.headers.host;
  const hostname_parts = req.headers.host.split( '.' );
  const parts = hostname_parts.length;
  let app_data;

  if (req.headers.host.match(/^www/) !== null) {
    res.writeHead(301, {
      location: process.env.SCHEME + req.headers.host.replace(/^www./, "") + (Checkers.isValidString(process.env.PORT) ? ':' + process.env.PORT : '') + req.url,
    });
    res.end();
  }

  let subdomain = null;

  if ( parts === 2 ) {
    if ( domain == 'nextjs.local' ) {
      // 1.33 KB
      app_data = await fetchData( redis, "/api/lodge/frontend/get/slug/check/", "domain", "villa-elli" ).then( ( response ) => { return response; })
    } else {
      // 945 B
      app_data = await fetchData( redis, "/api/lodge/frontend/get/domain/check/", "domain", domain ).then( ( response ) => { return response; })
    }
  } else if ( parts === 3 && domain.includes('.local')) {
    subdomain = req.headers.host.split( '.' )[0];
    app_data = fetchData( redis, "/api/lodge/frontend/get/slug/check/", "domain", subdomain ).then( ( response ) => { return response; })
  } else if ( parts === 3 && !domain.includes('loggiabuilder.net')) {
    app_data = await fetchData( redis, "/api/lodge/frontend/get/domain/check/", "domain", domain ).then( ( response ) => { return response; })
  } else if ( parts === 3 ) {
    subdomain = req.headers.host.split( '.' )[0];
    // 1.33 KB
    app_data = fetchData( redis, "/api/lodge/frontend/get/slug/check/", "domain", subdomain ).then( ( response ) => { return response; })
  }

  return app_data;
}

export async function fetchRewriteData ( req, redis, page_id, sourcePath ) {
  return fetchData( redis, "/api/lodge/frontend/get/app/rewrite", "page", page_id, { page_id: page_id, source_path: sourcePath }).then( ( response ) => { return response; })
}

export async function fetchPageData ( redis, page_id ) {
  return fetchData( redis, '/api/lodge/frontend/get/page/', 'page', page_id );
}

export async function fetchHeadData ( redis, page_id ) {
  return fetchData( redis, '/api/lodge/frontend/get/head/', 'page', page_id )
}

export async function fetchNavigationData ( redis, page_id ) {
  return fetchData( redis, '/api/lodge/frontend/get/navigation/', 'page', page_id )
}

export async function fetchLocationsData ( redis, page_id ) {
  return fetchData( redis, '/api/lodge/frontend/get/page/locations/', 'page', page_id )
}

export async function fetchFooterData ( redis, page_id ) {
  return fetchData( redis, "/api/lodge/frontend/get/navigation/footer/", "page", page_id )
}

export async function fetchDestinationContent ( redis, page_id, destination_id, locale = 'en' ) {
  const destination_params = {
    destination_id: destination_id,
    page_id: page_id,
    locale: locale
  };

  return fetchData( redis, "/api/lodge/properties/destination", "destination", page_id + '_' + destination_id, destination_params, 0, page_id )
}

export async function fetchPropertyContent ( redis, page_id, property_id, store, locale = 'en' ) {
  let checkIn = store.getState().propertiesParams.checkin
  let checkOut = store.getState().propertiesParams.checkout
  let availabilityData = await fetchPropertyAvailability(redis, page_id, property_id, checkIn, checkOut, 0)
  let availabilityDataForCheckout = await fetchPropertyAvailability(redis, page_id, property_id, checkIn, checkOut, 1)
  store.dispatch(updatepropertyAvailability(availabilityData))
  store.dispatch(updatepropertyAvailabilityForCheckout(availabilityDataForCheckout))
  store.dispatch(setPropertyId(property_id))

  let content = await fetchData( redis, "/api/lodge/frontend/get/property/content/", "property", property_id, null, 0, page_id, true) //, null, 1, page_id

  const isChain = Checkers.valueToBoolean(content.details.is_chain) ? 1 : 0

  const isHotel = content.details.system_type_id == 2 ? 1 : 0

  await store.dispatch( setIsHotel(isHotel) )
  await store.dispatch( setIsChain(isChain) )
  if(isChain){
    await store.dispatch( setLoadingProperties(true) )
  }

  await store.dispatch( setIsBookingsActive(content.is_bookings_active) )
  await store.dispatch( setCapacityMax(content.details.capacity_max) )
  await store.dispatch( setIsOpenCalendar(content.is_open_calendar) )
  await store.dispatch( setHasWebhotelier(content.has_webhotelier) )
  await store.dispatch( setBookingType(content.booking_type.type) )
  await store.dispatch( setHidePrices(content.hide_prices) )
  await store.dispatch( setBookUrl(content.webhotelier_book_url) )
  await store.dispatch( setBookingBedroomId(content.bedroom_id) )
  await store.dispatch( setInquiriesOnly(content.inquiries_active) )
  await store.dispatch( setRoomCode(content.webhotelier_room_code) )
  await store.dispatch( setPropertySlug(content.details.url_slug) )
  await store.dispatch( setPropertyClassification("4 stars") )
  await store.dispatch( setPropertyTypeTitle(content.type_title) )
  await store.dispatch( setIsExperiencesPage( 1 ) )
  if(content.details.wh_direct_checkout==true) {
    await store.dispatch(updateWhDirectCheckout(true))
  }else{
    await store.dispatch(updateWhDirectCheckout(false))
  }

  return content
}

export async function fetchPropertiesListCountFilters ( store, redis, page_id, properties_params ) {
  let properties_count = await fetchPropertiesCount( redis, page_id, properties_params )
  let propertiesFilters = await fetchPropertiesFilters( redis, page_id, properties_params )

  store.dispatch( setPropertiesFiltersData( propertiesFilters.filters ) )
  store.dispatch( setIsListingPage( true ) )

  return { properties_count, propertiesFilters }
}

export async function fetchPropertiesCount ( redis, page_id, properties_params, locale = 'en' ) {
  let data = await fetchData( redis, "/api/builder/properties/manager/list/count", "destination", page_id, properties_params, 0, page_id )
  return data.properties;
}

export async function fetchPropertiesData ( store, redis, page_id, properties_params, locale = 'en' ) {
  if(properties_params.hasOwnProperty('bedrooms') && Checkers.isValidPositiveNumber(properties_params.bedrooms)){
    properties_params.sortingField = 'bedrooms'
    properties_params.sortOrder = 'asc'
  }

  let properties = await fetchData( redis, "/api/builder/properties/manager/list/data/v2", "properties", page_id, properties_params, 1, null, true )
  store.dispatch( setPropertiesData( properties ) )
  return properties
}

export async function fetchPropertiesFilters ( redis, page_id, properties_params, locale = 'en' ) {
  return fetchData( redis, "/api/builder/properties/list/filters", "villas_collection_filters", page_id, properties_params,null, page_id )
}

export async function fetchHtmlSectionsData ( redis, page_id, locale = 'en' ) {
  return fetchSectionsData( redis, '/api/lodge/frontend/get/app-sections/', 'page', page_id, '?type=body' )
}

export async function fetchScriptSectionsData ( redis, page_id, locale = 'en' ) {
  return fetchSectionsData( redis, '/api/lodge/frontend/get/app-sections/', 'page', page_id, '?type=script' )
}

export async function fetchContentData ( redis, page_id, locale = 'en' ) {
  return fetchData( redis, '/api/lodge/frontend/get/content/', 'page', page_id )
}

export async function fetchDistinctsData ( redis, page_id, locale = 'en' ) {
  return fetchData( redis, '/api/lodge/frontend/get/page/distincts/', 'page', page_id )
}

export async function fetchCategoriesData ( redis, page_id, locale = 'en' ) {
  return fetchData( redis, '/api/lodge/frontend/builder/categories/', 'page', page_id )
}

export async function fetchExperiencesCategoriesData ( redis, page_id, locale = 'en' ) {
  return fetchData( redis, '/api/lodge/frontend/builder/experiences/categories/', 'page', page_id )
}

export async function fetchAllExperiencesCategoriesData ( redis, page_id, locale = 'en' ) {
  return fetchData( redis, '/api/lodge/frontend/builder/experiences/categories/all/', 'page', page_id )
}

export async function fetchHomepageCategoriesData ( redis, page_id, locale = 'en' ) {
  let categoriesContentDefaultRes = []
  let categoriesContentPromotedRes = []
  let categoriesContentPromoted2Res = []

  let categories = await fetchCategoriesData( redis, page_id )

  let categoriesDefault = categories['default'];

  if(categoriesDefault != null && categoriesDefault.length>0) {
    for (let i = 0; i < categoriesDefault.length; i++) {
      let item = categoriesDefault[i];
      let response = await fetchCategoryData(redis, page_id, item.id)
      categoriesContentDefaultRes.push(response)
    }
  }

  let categoriesPromoted = categories['promoted'];

  if(categoriesPromoted != null && categoriesPromoted.length>0) {
    for (let i = 0; i < categoriesPromoted.length; i++) {
      let item = categoriesPromoted[i];
      let response = await fetchCategoryData(redis, page_id, item.id)
      categoriesContentPromotedRes.push(response)
    }
  }

  let categoriesPromoted2 = categories['promoted2'];

  if(categoriesPromoted2 != null && categoriesPromoted2.length>0) {
    for (let i = 0; i < categoriesPromoted2.length; i++) {
      let item = categoriesPromoted2[i];
      let response = await fetchCategoryData(redis, page_id, item.id)
      categoriesContentPromoted2Res.push(response)
    }
  }

  return { categoriesContentDefaultRes, categoriesContentPromotedRes, categoriesContentPromoted2Res }
}

export async function fetchHomepageExperiencesCategoriesData ( redis, page_id, locale = 'en' ) {
  let categoriesContentDefaultRes = []
  let categoriesContentPromotedRes = []
  let categoriesContentPromoted2Res = []

  let categories = await fetchExperiencesCategoriesData( redis, page_id )

  let categoriesDefault = categories['default'];

  if(categoriesDefault != null && categoriesDefault.length>0) {
    for (let i = 0; i < categoriesDefault.length; i++) {
      let item = categoriesDefault[i];
      let response = await fetchExperienceCategoryData(redis, page_id, item.id)
      categoriesContentDefaultRes.push(response)
    }
  }

  let categoriesPromoted = categories['promoted'];

  if(categoriesPromoted != null && categoriesPromoted.length>0) {
    for (let i = 0; i < categoriesPromoted.length; i++) {
      let item = categoriesPromoted[i];
      let response = await fetchExperienceCategoryData(redis, page_id, item.id)
      categoriesContentPromotedRes.push(response)
    }
  }

  let categoriesPromoted2 = categories['promoted2'];

  if(categoriesPromoted2 != null && categoriesPromoted2.length>0) {
    for (let i = 0; i < categoriesPromoted2.length; i++) {
      let item = categoriesPromoted2[i];
      let response = await fetchExperienceCategoryData(redis, page_id, item.id)
      categoriesContentPromoted2Res.push(response)
    }
  }

  return { categoriesContentDefaultRes, categoriesContentPromotedRes, categoriesContentPromoted2Res }
}


export async function fetchExperiencesPageCategoriesData ( redis, page_id, locale = 'en' ) {
  let categoriesContentDefaultRes = []

  let categories = await fetchAllExperiencesCategoriesData( redis, page_id )

  let categoriesDefault = categories['default'];

  if(categoriesDefault != null && categoriesDefault.length>0) {
    for (let i = 0; i < categoriesDefault.length; i++) {
      let item = categoriesDefault[i];
      let response = await fetchExperienceCategoryData(redis, page_id, item.id)
      categoriesContentDefaultRes.push(response)
    }
  }

  return categoriesContentDefaultRes
}

export async function fetchPageProfileData ( redis, page_id, locale = 'en' ) {
  return fetchData( redis, '/api/lodge/frontend/get/page/profile/', 'page', page_id )
}

export async function fetchDestinationsData ( redis, page_id, locale = 'en' ) {
  return fetchData( redis, '/api/lodge/frontend/builder/destinations/details/', 'page', page_id )
}

export async function fetchColllectionsData ( redis, page_id, locale = 'en' ) {
  return fetchData( redis, '/api/lodge/frontend/builder/collections/', 'page', page_id )
}

export async function fetContactDetails ( redis, page_id, locale = 'en' ) {
  return fetchData( redis, '/api/lodge/frontend/get/page/contact-details/', 'page', page_id )
}

export async function fetchCategoryData ( redis, page_id, category_id, locale = 'en' ) {
  return fetchData( redis, "/api/lodge/frontend/get/category/content", "page", page_id + '_' + category_id, { page_id: page_id, category_id: category_id })
}

export async function fetchExperienceCategoryData ( redis, page_id, category_id, locale = 'en' ) {
  return fetchData( redis, "/api/lodge/frontend/get/experiences/category/content", "page", page_id + '_' + category_id, { page_id: page_id, experience_category_id: category_id })
}

export async function fetchBlogContentData ( redis, page_id, locale = 'en' ) {
  return fetchData( redis, "/api/lodge/frontend/get/blog/content/", "page", page_id )
}

export async function fetchArticleContentData ( redis, page_id, article_id, locale = 'en' ) {
  return fetchData( redis, "/api/lodge/frontend/get/article/content", "page", page_id + '_' + article_id, { page_id: page_id, article_id: article_id })
}

export async function fetchExperienceContentData ( redis, page_id, experience_id, locale = 'en' ) {
  return fetchData( redis, "/api/lodge/frontend/get/experiences/article/content", "page", page_id + '_' + experience_id, { page_id: page_id, experience_id: experience_id })
}

export async function fetchSettingsData ( redis, page_id, store = null ) {
  // ( redis, endpoint, type = null, key = null, params = null, bypass_key = 0, page_id = null, showConsoleLogs = false )
  // const settings = await fetchData( redis, '/api/lodge/frontend/get/settings/', 'page', page_id, null, 0, null, true )
  const settings = await fetchData( redis, '/api/lodge/frontend/get/settings/', 'page', page_id )
  if(store!=null) {
    // console.log("forDebug", "settings", settings)
    store.dispatch(setBuilderSettingsData(settings))
  }

  return settings
}

export async function fetchFiltersData ( redis, page_id, destination = null, store = null ) {
  const filters = await fetchData( redis, '/api/lodge/properties/list/filters/v2', 'page', null, {page_id: page_id, destinations : destination} )
  if(store!=null) {
    for(let i=0; i<filters.length; i++){
      if(filters[i].key=='bedrooms'){
        const options = filters[i].options

        if(options.hasOwnProperty('min') && Checkers.isValidNumber(options.min)){
          store.dispatch(setMinBedrooms(options.min))
        }

        if(options.hasOwnProperty('max') && Checkers.isValidPositiveNumber(options.max)){
          store.dispatch(setMaxBedrooms(options.max))
        }
      }
    }
  }
}

export async function fetchSystemData ( redis, page_id, store = null, locale = 'en', destination = null ) {
  if ( Checkers.isValidString(process.env.CAPTCHASITEKEY) ) {
    store.dispatch( updateCaptchaSiteKey(process.env.CAPTCHASITEKEY) )
  }

  let app = await fetchSettingsData( redis, page_id, store );

  await fetchFiltersData( redis, page_id, destination, store );

  let hideUnavailableProperties = app['builder']['builder_propertylist_hide_unavailable'];

  if ( store!=null ) {
    store.dispatch(updateHideUnavailableProperties(hideUnavailableProperties))
  }

  let page = await fetchPageData( redis, page_id );

  let head = await fetchHeadData( redis, page_id );

  let navigation = await fetchNavigationData( redis, page_id );

  let locations = await fetchLocationsData( redis, page_id );

  let footer = await fetchFooterData( redis, page_id )

  let html_sections = await fetchHtmlSectionsData( redis, page_id )

  return { app, page, head, navigation, locations, footer, html_sections }
}

export async function fetchPropertyAvailability  ( redis, page_id, propertyId, checkIn, checkOut, forDeparture, locale = 'en' ) {
  // let action = '/api/lodge/bookings/booking_plan?property_ids=' + `${propertyId}` +'&for_departure=' + `${forDeparture}` +'&date_from=' + `${checkIn}` +'&date_to=' + `${checkOut}`;
  // console.log("forDebug", "action", action)

  return fetchData( redis, '/api/lodge/bookings/booking_plan?property_ids=' + `${propertyId}` +'&for_departure=' + `${forDeparture}` +'&date_from=' + `${checkIn}` +'&date_to=' + `${checkOut}`, "page", page_id + '_' + propertyId, null, 1)
}